.field-cards {
  height: 550px;
  background-size: contain, cover;
  object-fit: cover;
}

.bottom-text {

}
.top-text {
    margin-top:20px;
}

.login-component {
    margin-top: 100px; 
    margin-bottom: 130px;
}
